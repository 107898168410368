<template>
  <div class="regional-package">
    <container-with-title
      title="支持主流平台出海带货直播"
      operate-text=""
      class="platform-device-wrap"
    >
      <div class="platform-device">
        <div class="platform">
          <div class="module-name">支持平台</div>
          <platform-list />
        </div>
        <div class="device">
          <div class="module-name">支持设备</div>
          <device-list />
        </div>
      </div>
    </container-with-title>
    <div class="package-info-wrap">
      <div class="area-package">
        <div class="module-name">可用地域</div>
        <div class="area-list">
          <div
            v-for="(item, index) in areaList"
            :key="item.route"
            class="area-item"
            :class="{ active: activeAreaIndex === index }"
            @click="activeAreaIndex = index"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.route }}</span>
          </div>
        </div>
      </div>
      <div class="module-name">可选套餐</div>
      <div class="package-list">
        <div
          v-for="(item, index) in packageList"
          :key="index"
          class="package-item"
          :class="{ active: activePackageIndex === index }"
          @click="activePackageIndex = index"
        >
          <div class="price-hour">
            <span>低至</span>
            <span>￥{{ item.price | priceHour }}</span>
            <span>/</span>
            <span>小时</span>
          </div>
          {{ item.name }}
        </div>
      </div>
      <template v-if="connectionType === ConnectionTypeEnum.Direct">
        <div class="module-name">硬件设备</div>
        <div class="hardware-list">
          <div
            class="hardware-item"
            :class="{ active: activeHardwareIndex === 0 }"
            @click="activeHardwareIndex = 0"
          >
            3.1GHz双核+4G
          </div>
          <div
            class="hardware-item"
            :class="{ active: activeHardwareIndex === 1 }"
            @click="activeHardwareIndex = 1"
          >
            3.1GHz四核 + 8G
          </div>
        </div>
      </template>
      <div class="module-name">使用时长</div>
      <div class="duration">
        <div class="moon" @click="duration = 'moon'">
          <a-radio value="moon" :checked="duration === 'moon'">
            <div class="input-number-wrap">
              <span>按月购买</span>
              <my-input-number v-model="moon" />
              <span class="unit">月</span>
            </div>
          </a-radio>
        </div>
        <div class="year" @click="duration = 'year'">
          <a-radio value="year" :checked="duration === 'year'">
            <div class="input-number-wrap">
              <span>按年购买</span>
              <my-input-number v-model="year" />
              <span class="unit">年</span>
            </div>
          </a-radio>
        </div>
      </div>
    </div>
    <div class="price-container">
      <div class="total-cost">
        <span class="total">套餐</span>
        <span class="price">
          <span>{{ total }}元</span>
        </span>
        <span v-if="$route.path === '/programme/special-line'"
          >+设备押金1000元</span
        >
        <span class="total-num"
          >共计
          {{
            Number(total) +
            ($route.path === "/programme/special-line" ? 1000 : 0)
          }}</span
        >
      </div>
      <div class="tips">
        <i class="iconfont icon-jinggao-09"></i>
        <span>提示：如需购买，请在电脑端打开e路播官网</span>
      </div>
      <div class="btn" @click="toPC">跳转官网地址</div>
      <div class="description" @click="copy">
        点击<span class="here">这里</span>复制官网地址
      </div>
    </div>
    <!--<copy-modal ref="copyModal" />-->
  </div>
</template>

<script>
import { Radio, InputNumber } from "ant-design-vue";
// import CopyModal from "./CopyModal";
// import TitleUnderline from "../../components/TitleUnderline";
import PlatformList from "./PlatformList";
import DeviceList from "./DeviceList";
// 下面是国旗图片
import America from "@/assets/images/purchase/America.png";
import Thailand from "@/assets/images/purchase/Thailand.png";
import Malaysia from "@/assets/images/purchase/Malaysia.png";
import Japan from "@/assets/images/purchase/Japan.png";
import SouthKorea from "@/assets/images/purchase/SouthKorea.png";
import Singapore from "@/assets/images/purchase/Singapore.png";
import Vietnam from "@/assets/images/purchase/Vietnam.jpg";
import China from "@/assets/images/purchase/China.png";
import Indonesia from "@/assets/images/purchase/Indonesia.png";
import Britain from "@/assets/images/purchase/Britain.png";
// 上面是国旗图片
import { packageList } from "@/request/package";
import { formatCentToYuan } from "@/utils/format";
import { getIsLogin } from "@/utils/utils";
import { checkRealName } from "@/request/user";
import ContainerWithTitle from "../../components/ContainerWithTitle";
import MyInputNumber from "../../components/InputNumber";
import { autoCopy } from "../../../utils/utils";
import { websiteUrl } from "../../constant/system";
import { ConnectionTypeEnum } from "../../constant/system";

export default {
  components: {
    MyInputNumber,
    ContainerWithTitle,
    [Radio.name]: Radio,
    [InputNumber.name]: InputNumber,
    // TitleUnderline,
    PlatformList,
    DeviceList,
    // CopyModal,
  },
  data() {
    return {
      areaList: [],
      activeAreaIndex: 0,
      activePackageIndex: 0,
      activeHardwareIndex: 0,
      duration: "moon",
      moon: 1,
      year: 1,
      isLogin: false,
      authStatus: undefined,
      ConnectionTypeEnum,
    };
  },
  props: {
    connectionType: Number,
  },
  watch: {
    activeAreaIndex() {
      this.activePackageIndex = 0;
    },
  },
  computed: {
    packageList() {
      const areaPackage = this.areaList[this.activeAreaIndex];
      return areaPackage ? areaPackage.products || [] : [];
    },
    total() {
      const months = this.duration === "moon" ? 1 * this.moon : 12 * this.year;
      const price = this.packageList[this.activePackageIndex]
        ? this.packageList[this.activePackageIndex].price
        : 0;
      return formatCentToYuan(price * months);
    },
  },
  filters: {
    priceHour(price) {
      return formatCentToYuan(price / 30 / 24);
    },
  },
  created() {
    this.getPackageList();
    this.isLogin = getIsLogin();
    if (this.isLogin) {
      checkRealName().then((res = {}) => {
        const { realNameAuthState } = res;
        this.authStatus = realNameAuthState;
      });
    }
  },
  methods: {
    getPackageList() {
      packageList(this.connectionType).then((res) => {
        for (const item of res) {
          if (item.route.includes("北美")) {
            item.icon = America;
          } else if (item.route.includes("香港")) {
            item.icon = China;
          } else if (item.route.includes("台湾")) {
            item.icon = China;
          } else if (item.route.includes("南美")) {
            item.icon = Indonesia;
          } else if (item.route.includes("英国")) {
            item.icon = Britain;
          } else if (item.route.includes("越南")) {
            item.icon = Vietnam;
          } else if (item.route.includes("新加坡")) {
            item.icon = Singapore;
          } else if (item.route.includes("日本")) {
            item.icon = Japan;
          } else if (item.route.includes("马来西亚")) {
            item.icon = Malaysia;
          } else if (item.route.includes("泰国")) {
            item.icon = Thailand;
          } else if (item.route.includes("韩国")) {
            item.icon = SouthKorea;
          } else if (item.route.includes("美国")) {
            item.icon = America;
          } else if (item.route.includes("印尼")) {
            item.icon = Indonesia;
          }
        }
        this.areaList = res || [];
      });
    },
    startNow() {
      if (!this.isLogin) {
        this.$router.push("/login");
      } else if (this.authStatus === 1) {
        this.$router.push({
          path: "/user/purchase",
          query: {
            connectionType: this.connectionType,
          },
        });
      } else {
        this.$router.push("/user/info");
      }
    },
    toPC() {
      window.location.href = `${websiteUrl}?device=pc`;
    },
    copy() {
      autoCopy(websiteUrl);
      this.$myToast({
        type: "success",
        message: "官网地址复制成功",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";

::v-deep .ant-radio-wrapper > span.ant-radio .ant-radio-inner::after {
  border-radius: 50%;
}

//@media screen and (max-width: 375px) {
//  .price-hour span {
//    &:nth-child(1),
//    &:nth-child(4) {
//      transform: scale(0.5) !important;
//    }
//
//    &:nth-child(2),
//    &:nth-child(3) {
//      zoom: 0.8;
//    }
//  }
//}

.regional-package {
  .module-name {
    font-size: 75px;
    font-weight: bold;
    color: #39425c;
    margin-bottom: 40px;
  }

  .platform-device-wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-top: 50px;
  }

  .platform-device {
    padding: 68px;
    background: #ffffff;
    box-shadow: 0px 0px 27px 7px rgba(67, 92, 130, 0.1);
  }

  .package-info-wrap {
    width: 1766px;
    margin: 17px auto 0;
    background: #ffffff;
    box-shadow: 0px 0px 27px 7px rgba(67, 92, 130, 0.1);
    padding: 68px;

    .area-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 50px;

      .area-item {
        width: 100%;
        height: 205px;
        background: #dce7ff;
        @include flex(row, center, center);
        border: 2px solid #ffffff;
        position: relative;

        &.active {
          background: #3661eb;
          span {
            color: #ffffff;
            font-weight: bold;
          }
        }

        img {
          width: 101px;
          height: 68px;
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 0;
        }

        span {
          font-size: 67px;
          color: #39425c;
        }
      }
    }

    .package-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 67px;

      .package-item {
        height: 205px;
        background: #dce7ff;
        border: 2px solid #ffffff;
        position: relative;
        margin-top: 93px;
        font-size: 67px;
        color: #39425c;
        @include flex(row, center, center);

        &.active {
          color: #ffffff;
          font-weight: Bold;
          background-color: #3661eb;
          border: 0;
          .price-hour {
            display: flex;
          }
        }

        .price-hour {
          position: absolute;
          width: 100%;
          height: 102px;
          background: #fc9415;
          top: -102px;
          justify-content: center;
          align-items: center;
          display: none;
          word-break: keep-all;

          span {
            color: #ffffff;
            //transform: scale(0.8);
            &:nth-child(1) {
              transform: scale(0.8);
              transform-origin: right center;
            }
            &:nth-child(4) {
              transform: scale(0.8);
              transform-origin: left center;
            }
            &:nth-child(1),
            &:nth-child(3),
            &:nth-child(4) {
              font-size: 31px;
            }
            &:nth-child(2) {
              font-size: 51px;
              font-weight: bold;
              position: relative;
              top: -5px;
            }
          }
        }
      }
    }

    .hardware-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 67px;

      .hardware-item {
        height: 205px;
        background: #dce7ff;
        border: 2px solid #ffffff;
        font-size: 51px;
        font-weight: bold;
        color: #39425c;
        @include flex(row, center, center);

        &.active {
          background: #3661eb;
          color: #ffffff;
        }
      }
    }

    .duration {
      height: 410px;
      background: #e3eafa;
      @include flex(column, center);
      .moon {
        margin-bottom: 40px;
      }

      .moon,
      .year {
        height: 140px;
        ::v-deep .ant-radio {
          margin-left: 51px;
        }

        ::v-deep .ant-radio-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
        }

        .input-number-wrap {
          display: flex;
          align-items: center;
          width: 100%;
          /* prettier-ignore */
          padding-left: calc(44px);
          height: 140px;

          .unit {
            margin-left: 38px;
          }
        }
        span {
          color: #39425c;
          font-size: 67px;
          margin-right: 38px;
        }
      }
    }
  }

  .price-container {
    width: 1766px;
    margin: 0 auto;
    height: 712px;
    padding: 0 72px;
    background: rgba(255, 255, 255, 0.5);
    @include flex(column, center, center);
    border-bottom-left-radius: 51px;
    border-bottom-right-radius: 51px;

    .total-cost {
      font-size: 72px;
      font-weight: bold;
      color: #fc9415;
      text-align: center;

      .total-num {
        margin-left: 80px;
      }
    }

    .tips {
      font-size: 67px;
      color: #39425c;
      align-self: flex-start;
      margin-top: 65px;
      margin-bottom: 68px;

      .iconfont {
        color: #ffa929;
        margin-right: 22px;
      }
    }

    .btn {
      @include flex(row, center, center);
      width: 1263px;
      height: 205px;
      background: linear-gradient(0deg, #4385f4 0%, #5796ff 100%);
      box-shadow: 0px 34px 51px 0px rgba(64, 136, 255, 0.5);
      border-radius: 102px;
      font-size: 92px;
      font-weight: bold;
      color: #ffffff;
      letter-spacing: 2px;
    }

    .description {
      font-size: 51px;
      font-weight: 400;
      color: #8f9199;
      margin-top: 68px;
      letter-spacing: 2px;

      .here {
        color: #3661eb;
      }
    }
  }
}
</style>
