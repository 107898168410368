<template>
  <div class="platform-list">
    <div class="platform-item" v-for="item in SupportPlatform" :key="item.name">
      <div class="img-container">
        <img :src="item.icon" />
      </div>
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { SupportPlatform } from "../../constant/system";

export default {
  data() {
    return {
      SupportPlatform,
    };
  },
};
</script>

<style lang="scss" scoped>
//@media screen and (max-width: 375px) {
//  .platform-list span {
//    transform: translateX(-50%) scale(0.8) !important;
//  }
//}
.platform-list {
  width: 100%;
  margin-bottom: 137px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  .platform-item {
    position: relative;
    &:nth-child(3),
    &:nth-child(6) {
      margin-right: 0;
    }

    .img-container {
      width: 137px;
      height: 137px;
      background: #ffffff;
      box-shadow: 0px 10px 9px 1px rgba(0, 68, 142, 0.24);
      border-radius: 17px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    span {
      position: absolute;
      top: calc(137px + 34px);
      left: 50%;
      transform: translateX(-50%);
      color: #39425c;
      font-weight: bold;
      font-size: 51px;
    }
  }
}
</style>
