<template>
  <div class="xtx-number">
    <!--<div class="label"><slot /></div>-->
    <button @click="handleSub(-1)">-</button>
    <input type="number" :value="value" @blur="handleInputBlur" />
    <!-- readonly 是将通过ref 或reactive定义好的数据进行拷贝后变为仅可读的数据，不可进行修改，即无响应readonly 是将通过ref 或reactive定义好的数据进行拷贝后变为仅可读的数据，不可进行修改，即无响应 -->
    <button @click="handleSub(1)">+</button>
    <!--</div>-->
  </div>
</template>
<script lang="ts">
export default {
  data() {
    return {};
  },
  props: {
    min: {
      type: Number,
      default: 1, //最小值
    },
    max: {
      type: Number,
      default: Infinity, //最大值
    },
    value: {
      type: Number,
      default: 1, //计数
    },
  },

  methods: {
    handleInputBlur(e) {
      let valueStr = e.target.value;
      if (valueStr === "") {
        valueStr = `${this.min}`;
      }
      let value = parseInt(valueStr);
      if (value <= this.min) {
        value = this.min;
        e.target.value = value;
      } else if (value >= this.max) {
        value = this.max;
      }
      this.$emit("input", value);
    },
    handleSub(n) {
      let value = this.value;
      if (n < 0) {
        if (value <= this.min) {
          value = this.min;
        } else {
          value -= 1;
        }
      } else {
        if (value >= this.max) {
          value = this.max;
        } else {
          value += 1;
        }
      }
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss">
.xtx-number {
  display: flex;
  align-items: center;
  width: 430px;
  height: 140px;
  background: #ffffff;
  border: 2px solid #a5b5ce;
  border-radius: 7px;
  //.label {
  //  width: 60px;
  //  padding-left: 10px;
  //  color: #999;
  //}
  //.numbox {
  //  display: flex;
  //  width: 120px;
  //  height: 40px;
  //  border: 1px solid #e4e4e4;
  button {
    height: 100%;
    background: none;
    display: inline-block;
    flex: 1;
    text-align: center;
    border: none;
    font-size: 67px;
    color: #39425c;
    &:focus {
      outline: none;
    }
    &:first-of-type {
      border-right: 2px solid #a5b5ce;
    }
    &:last-of-type {
      border-left: 2px solid #a5b5ce;
    }
  }
  input {
    width: 140px;
    text-align: center;
    border: none;
    font-size: 67px;
    color: #39425c;
    &:focus {
      outline: none;
    }
  }
  //}
}
</style>
